import {
  listenToCollege,
  updateCollege,
  addColleges,
  deleteCollege,
} from "../../features/college/collegeReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const fetchCollegesApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "college" + query)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(addColleges(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const createCollegeApi = (college) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "college", {
    method: "POST",
    body: JSON.stringify(college),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => {
      console.log("Create College ", error.message);
      alert("Your college could not be created\nError: " + error.message);
    });
};

export const fetchSingleCollegeApi = (collegeId) => (dispatch) => {
  return fetch(process.env.REACT_APP_API_BASE_URL + "college/" + collegeId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(listenToCollege(response)))
    .catch((error) => console.log(error.message));
};

export const updateCollegeApi = (college) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "college/" + college._id, {
    method: "PUT",
    body: JSON.stringify(college),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateCollege(response)))
    .catch((error) => {
      console.log("Club updated ", error.message);
      alert("Your Club could not be updated\nError: " + error.message);
    });
};

export const deleteCollegeApi = (collegeId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "college/" + collegeId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("College Deleted", response);
      dispatch(deleteCollege(collegeId));
    })
    .catch((error) => console.log(error.message));
};