import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { loginUser } from "../../app/apiRequest/loginService";
import { Button, Label } from "semantic-ui-react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { openModal } from "../../app/common/modals/modalReducer";

export default function LoginHomePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/home");
    }
  }, [isAuthenticated, history]);

  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  return (
    <div>
      <div className="login-container flex">
        <nav>
          <a className="menu-item" href="/">
            <img src="/logo.png" alt="logo" className="logo" />
            Club-Chat
          </a>
          <div className="nav-links">
            <pre
              onClick={() =>
                dispatch(openModal({ modalType: "ContactUsForm" }))
              }
            >
              Contact
            </pre>
            <pre onClick={() => history.push("/about")}>About Us</pre>
          </div>
        </nav>

        <div className="login-content flex">
          <div className="contentDiv flex">
            <h2 className="contentText">Discover college, clubs and events</h2>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              try {
                dispatch(loginUser(values, setErrors));
              } catch (error) {
                setErrors({ auth: "Error in Login server!" });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, isValid, dirty, errors }) => (
              <Form className="ui login form">
                <MyTextInput name="username" placeholder="Email Address" />
                <MyTextInput
                  name="password"
                  placeholder="Password"
                  type="password"
                />
                {errors.auth && (
                  <Label
                    basic
                    color="red"
                    style={{ marginBottom: 10 }}
                    content={errors.auth}
                  />
                )}
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  fluid
                  size="large"
                  color="blue"
                  content="Login"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
