import {
  fetchCreationStats,
  fetchUserActivities,
} from "../../features/home/homeReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const fetchUserActivityApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "dashboard/" + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(fetchUserActivities(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const fetchCreationStatsApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(
    process.env.REACT_APP_API_BASE_URL + "dashboard/creation_stats/" + query,
    {
      headers: {
        Authorization: bearer,
      },
    }
  )
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(fetchCreationStats(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};
