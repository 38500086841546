const CONTACTS_LOADING = "CONTACTS_LOADING";
const CONTACTS_FAILED = "CONTACTS_FAILED";
const ADD_CONTACTS = "ADD_CONTACTS";
const DELETE_CONTACT = "DELETE_CONTACT";
const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONTACT_RETAIN_STATE = "CONTACT_RETAIN_STATE";

export const contactsLoading = () => ({
  type: CONTACTS_LOADING,
});

export const contactsFailed = (errmess) => ({
  type: CONTACTS_FAILED,
  payload: errmess,
});

export const addContacts = ({ contacts, moreContacts, lastDocId }) => ({
  type: ADD_CONTACTS,
  payload: { contacts, moreContacts, lastDocId },
});

export function updateContacts(contact) {
  return {
    type: UPDATE_CONTACT,
    payload: contact,
  };
}

export function deleteContacts(contactId) {
  return {
    type: DELETE_CONTACT,
    payload: contactId,
  };
}

const initialState = {
  isLoading: true,
  errMess: null,
  contacts: [],
  moreContacts: true,
  lastDocId: null,
  retainState: false,
};

export default function contactsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_CONTACTS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        contacts: [...state.contacts, ...payload.contacts],
        moreContacts: payload.moreContacts,
        lastDocId: payload.lastDocId,
      };

    case CONTACTS_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case CONTACTS_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case UPDATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((evt) =>
          evt._id === payload._id ? payload : evt
        ),
      };

    case DELETE_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts.filter((evt) => evt._id !== payload)],
      };
    case CONTACT_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
