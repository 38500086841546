import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  Button,
  Header,
  Icon,
  Image,
  Item,
  Label,
  List,
} from "semantic-ui-react";
import {
  fetchSingleClubApi,
  updateClubApi,
} from "../../app/apiRequest/clubService";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Helmet } from "react-helmet";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom";
import displayLinks from "../../app/common/text/handleLinkClick";
import { openModal } from "../../app/common/modals/modalReducer";
import { toast } from "react-toastify";

export default function ClubAboutPage({ match }) {
  const [panels, setPenals] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedClub } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchSingleClubApi(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (selectedClub) {
      selectedClub.faqs.map((faq) =>
        setPenals((oldArray) => [
          ...oldArray,
          {
            key: faq._id,
            title: faq.question,
            content: displayLinks(faq.answer),
          },
        ])
      );
    }
  }, [selectedClub]);

  const handleApprove = () => {
    dispatch(updateClubApi({ ...selectedClub, college_status: "unverified" }));
    toast.success("Club is approved to be on College Page as Unofficial Club.");
  };

  const handleReject = () => {
    dispatch(updateClubApi({ ...selectedClub, college: null }));
    toast.success("Club is rejected.");
  };

  if ((loading && !selectedClub) || (!error && !selectedClub))
    return <LoadingComponent content="Loading..." />;

  if (error) return <Redirect to="/error" />;

  return (
    <div>
      <Button
        icon="arrow left"
        floated="left"
        style={{ top: 78, left: 60, position: "fixed", color: "blue" }}
        onClick={() => history.goBack()}
      />
      <div style={{ marginRight: 20 }}>
        <Item.Group>
          <Item>
            <Item.Content>
              <Image
                src={selectedClub.clubImg}
                size="small"
                floated="right"
                style={{ marginRight: "15vw" }}
              />
              <Item.Meta as="a" style={{ color: "blue", display: "block" }}>
                {selectedClub.college ? selectedClub.college.collegeName : ""}
              </Item.Meta>
              <Item.Header>{selectedClub.clubName}</Item.Header>
              <Item.Description>
                <p style={{ width: 500 }}>{selectedClub.description}</p>
                <List horizontal relaxed style={{ display: "block" }}>
                  {selectedClub.website && (
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkify" />{" "}
                        <a href={selectedClub.website} target="#blank">
                          {selectedClub.website}
                        </a>
                      </List.Content>
                    </List.Item>
                  )}
                  <List.Item>
                    <List.Content>
                      <List.Icon name="mail" />{" "}
                      <a href={`mailto:${selectedClub.email}`} target="#blank">
                        {selectedClub.email}
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
                {selectedClub.instagram && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="instagram" />{" "}
                        <a href={selectedClub.instagram} target="#blank">
                          {selectedClub.instagram}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                {selectedClub.linkedIn && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkedin" />{" "}
                        <a href={selectedClub.linkedIn} target="#blank">
                          {selectedClub.linkedIn}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
              </Item.Description>
              <Item.Extra>
                {selectedClub.college_status === "rejected" &&
                  selectedClub.college && (
                    <Button.Group>
                      <Button onClick={handleApprove} basic color="green">
                        Approve
                      </Button>
                      <Button onClick={handleReject} basic color="red">
                        Decline
                      </Button>
                    </Button.Group>
                  )}
                {selectedClub.college_status !== "rejected" && (
                  <Label basic color="blue">
                    <Icon name="check circle outline" />
                    Approved
                  </Label>
                )}
                <Button
                  color="blue"
                  icon="eye"
                  content="View Admin"
                  style={{ marginTop: 30 }}
                  onClick={() => {
                    dispatch(
                      openModal({
                        modalType: "ViewAdminModal",
                        modalProps: {
                          admin: selectedClub.admin,
                        },
                      })
                    );
                  }}
                />
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </div>
      <div style={{ marginTop: 16, marginLeft: 8 }}>
        <Header content="What will you learn?" />
        <List bulleted>
          {selectedClub.learnings.map(
            (learning, i) =>
              learning.learning !== "" && (
                <List.Item content={learning.learning} key={i} />
              )
          )}
        </List>
        <Header>Some important frequently asked questions</Header>
        <Accordion
          panels={panels}
          defaultActiveIndex={[0]}
          exclusive={false}
          fluid
        />
      </div>
      <Helmet>
        <title>{selectedClub.clubName}</title>
        <meta name="description" content={selectedClub.description} />
        <meta property="og:title" content={selectedClub.clubName} />
        <meta property="og:description" content={selectedClub.description} />
        <meta property="og:image" content={selectedClub.clubImg} />
      </Helmet>
    </div>
  );
}
