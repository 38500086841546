import React from "react";
import "./ReportListItem.css";
import { useDispatch } from "react-redux";
import { updateReportApi } from "../../app/apiRequest/reportService";

const ReportListItem = ({ report }) => {
  const dispatch = useDispatch();
  const onStatusChange = (status) => {
    dispatch(updateReportApi({ ...report, status: status }));
  };

  var link = "/";
  if (report.reportOn === "post") {
    link = `https://clubchat.live/posts/${report.reportedId}`;
  } else if (report.reportOn === "profile") {
    link = `https://clubchat.live/profile/${report.reportedId}`;
  } else if (report.reportOn === "college") {
    link = `https://clubchat.live/college/${report.reportedId}`;
  } else if (report.reportOn === "festival") {
    link = `https://clubchat.live/club/fest/${report.reportedId}`;
  } else if (report.reportOn === "club") {
    link = `https://clubchat.live/club/about/${report.reportedId}`;
  } else if (report.reportOn === "event") {
    link = `https://clubchat.live/events/${report.reportedId}`;
  } else if (report.reportOn === "eventComment") {
    link = `https://clubchat.live/events/${report.reportedId}`;
  } else if (report.reportOn === "chatRoom") {
    link = `https://clubchat.live/club/${report.reportedId}`;
  } else if (report.reportOn === "chat") {
    link = `https://clubchat.live/posts/${report.reportedId}`;
  } else if (report.reportOn === "discussion") {
    link = `https://clubchat.live/posts/${report.reportedId}`;
  } else if (report.reportOn === "file") {
    link = `https://clubchat.live/posts/${report.reportedId}`;
  } else if (report.reportOn === "session") {
    link = `https://clubchat.live/posts/${report.reportedId}`;
  } else if (report.reportOn === "clubMember") {
    link = `https://clubchat.live/club/${report.reportedId}`;
  } else if (report.reportOn === "channelMember") {
    link = `https://clubchat.live/club/${report.reportedId}`;
  } else if (report.reportOn === "notification") {
    link = `https://clubchat.live/posts/${report.reportedId}`;
  }

  return (
    <div className="report">
      <div className="top-bar">
        <div className="info">
          <img src={report.reportedBy.userImg} avatar alt="Profile" />
          <div className="name">
            <a
              href={`https://clubchat.live/profile/${report.reportedBy._id}`}
              target="#blank"
            >
              {report.reportedBy.displayName}
            </a>
            <h4>{report.reportedBy.username}</h4>
          </div>
        </div>
        <div>
          <select
            value={report.status}
            onChange={(e) => onStatusChange(e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="processiong">Processing</option>
            <option value="resolved">Resolved</option>
          </select>
        </div>
      </div>
      <div className="main">
        <p>
          <b>{"Report : "}</b> {report.report}
        </p>
        {report.file !== "" && (
          <div className="attachment">
            <a href={report.file} target="#blank">
              {report.file}
            </a>
          </div>
        )}
      </div>
      <div className="btn">
        <button onClick={() => window.open(link, "_blank")}>
          <i className="fa-solid fa-bug"></i> Reported On: {report.reportOn}
        </button>
      </div>
    </div>
  );
};

export default ReportListItem;
