import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Header } from "semantic-ui-react";
import MyTextArea from "../../app/common/form/MyTextArea";
import MyTextInput from "../../app/common/form/MyTextInput";
import { closeModal } from "../../app/common/modals/modalReducer";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import {
  createFeaturedAdApi,
  updateAdApi,
} from "../../app/apiRequest/featuredAdService";
import { toast } from "react-toastify";

export default function FeaturedAdForm() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.async);
  const { ad } = useSelector((state) => state.modal.modalProps);

  const initialValues = ad
    ? {
        description: ad.description,
        sponsered: ad.sponsered,
        college: ad.college ? ad.college._id : null,
        fest: ad.fest ? ad.fest._id : null,
        event: ad.event ? ad.event._id : null,
        club: ad.club ? ad.club._id : null,
      }
    : {
        description: "",
        sponsered: false,
      };

  const validationSchema = Yup.object({
    description: Yup.string().required(),
  });

  const handleCancleBtn = () => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="tiny" header="Featured Oppurtunities Form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            console.log(values);
            if (ad != null) {
              values._id = ad._id;
              dispatch(updateAdApi(values));
            } else {
              dispatch(createFeaturedAdApi(values));
            }
            dispatch(closeModal());
            toast.success("Form Submitted");
          } catch (error) {
            console.log(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form className="ui form">
            <Header
              sub
              color="blue"
              content="Set only one Id at a time."
              style={{ marginBottom: 20 }}
            />
            <MyTextInput
              name="college"
              placeholder="College Id"
              label={"College Id"}
              disabled={values.fest || values.event || values.club}
            />
            <MyTextInput
              name="fest"
              placeholder="Festival Id"
              label={"Festival Id"}
              disabled={values.college || values.event || values.club}
            />
            <MyTextInput
              name="event"
              placeholder="Event Id"
              label={"Event Id"}
              disabled={values.fest || values.college || values.club}
            />
            <MyTextInput
              name="club"
              placeholder="Club Id"
              label={"Club Id"}
              disabled={values.fest || values.event || values.college}
            />
            <MyTextArea
              name="description"
              placeholder="Please include as much information as possible..."
              label={"Description"}
              rows={4}
            />
            <Checkbox
              name="sponsered"
              label="Sponsered"
              defaultChecked={values.sponsered}
              onClick={() => (values.sponsered = !values.sponsered)}
            />
            <Button
              loading={isSubmitting || loading}
              disabled={!isValid || !dirty || isSubmitting || loading}
              floated="right"
              type="submit"
              color="blue"
              content="Submit"
              style={{ margin: 10 }}
            />
            <Button
              loading={isSubmitting}
              floated="right"
              type="button"
              content="Cancel"
              color="grey"
              onClick={handleCancleBtn}
              style={{ margin: 10 }}
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
