/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Header,
  Table,
  Menu,
  MenuItem,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchCreationStatsApi,
  fetchUserActivityApi,
} from "../../app/apiRequest/homeService";
import { Helmet } from "react-helmet";

export default function HomePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user_activities, creation_stats } = useSelector(
    (state) => state.home
  );

  const { search } = useSelector((state) => state.router.location);
  const [activeItem, setActiveItem] = useState("days");
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() is 0-indexed

  useEffect(() => {
    setActiveItem(search);
  }, [search]);

  useEffect(() => {
    if (search === "") {
      history.push(
        `/home?query=days?year=${currentYear}&month=${currentMonth}`
      );
      return;
    }
    dispatch(fetchUserActivityApi(search.substring(7)));
    dispatch(fetchCreationStatsApi(search.substring(7)));
  }, [dispatch, search, history, currentMonth, currentYear]);

  const handleSetActiveItem = (e, { name }) => {
    if (name === "months") {
      history.push(`/home?query=months?year=${currentYear}`);
    } else if (name === "years") {
      history.push(`/home?query=years`);
    } else {
      history.push(
        `/home?query=days?year=${currentYear}&month=${currentMonth}`
      );
    }
  };

  // Merge function
  function mergeResponses(userActivityData, creationStatsData) {
    // Create a map for fast lookup of creation stats by period
    const creationStatsMap = new Map();
    creationStatsData.forEach((item) => {
      creationStatsMap.set(item.period, item.creationStats);
    });

    // Merge data
    const mergedData = userActivityData.map((item) => {
      const creationStats = creationStatsMap.get(item.period) || {};
      return {
        ...item,
        creationStats, // Add creationStats to each period
      };
    });

    return mergedData;
  }

  // Perform the merge
  const tableData = mergeResponses(user_activities, creation_stats);

  return (
    <div>
      <Menu text>
        <MenuItem header>Sort By</MenuItem>
        <MenuItem
          name="days"
          active={activeItem.includes("days")}
          color="blue"
          onClick={handleSetActiveItem}
        />
        <MenuItem
          name="months"
          active={activeItem.includes("months")}
          color="blue"
          onClick={handleSetActiveItem}
        />
        <MenuItem
          name="years"
          active={activeItem.includes("years")}
          color="blue"
          onClick={handleSetActiveItem}
        />
      </Menu>
      <div style={{ display: "flex", overflowX: "auto" }}>
        <Table celled structured striped>
          <TableHeader>
            <TableRow>
              <TableHeaderCell rowSpan="2">Period</TableHeaderCell>
              <TableHeaderCell textAlign="center" colSpan="5">
                Active User
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" colSpan="5">
                Joined Club
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" colSpan="5">
                Registered Event
              </TableHeaderCell>
              <TableHeaderCell textAlign="center" colSpan="6">
                Creation Stats
              </TableHeaderCell>
            </TableRow>
            <TableRow>
              <TableHeaderCell>Android</TableHeaderCell>
              <TableHeaderCell>IOS</TableHeaderCell>
              <TableHeaderCell>mWeb</TableHeaderCell>
              <TableHeaderCell>Web</TableHeaderCell>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell>Android</TableHeaderCell>
              <TableHeaderCell>IOS</TableHeaderCell>
              <TableHeaderCell>mWeb</TableHeaderCell>
              <TableHeaderCell>Web</TableHeaderCell>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell>Android</TableHeaderCell>
              <TableHeaderCell>IOS</TableHeaderCell>
              <TableHeaderCell>mWeb</TableHeaderCell>
              <TableHeaderCell>Web</TableHeaderCell>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell>User</TableHeaderCell>
              <TableHeaderCell>College</TableHeaderCell>
              <TableHeaderCell>Club</TableHeaderCell>
              <TableHeaderCell>Event</TableHeaderCell>
              <TableHeaderCell>Report</TableHeaderCell>
              <TableHeaderCell>Contacts</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {tableData.map((data, index) => (
              <TableRow key={index}>
                {activeItem.includes("days") && (
                  <TableCell singleLine>
                    <Header textAlign="center">{data.period}</Header>
                  </TableCell>
                )}
                {activeItem.includes("months") && (
                  <TableCell singleLine>
                    <Header
                      as="a"
                      href={`/home?query=days?year=${data.period.substring(
                        0,
                        4
                      )}&month=${data.period.substring(5)}`}
                      textAlign="center"
                    >
                      {data.period}
                    </Header>
                  </TableCell>
                )}
                {activeItem.includes("years") && (
                  <TableCell singleLine>
                    <Header
                      as="a"
                      href={`/home?query=months?year=${data.period}`}
                      textAlign="center"
                    >
                      {data.period}
                    </Header>
                  </TableCell>
                )}
                <TableCell singleLine>{data.activeUsers.android}</TableCell>
                <TableCell singleLine>{data.activeUsers.ios}</TableCell>
                <TableCell singleLine>{data.activeUsers.mWeb}</TableCell>
                <TableCell singleLine>{data.activeUsers.web}</TableCell>
                <TableCell singleLine>{data.activeUsers.total}</TableCell>
                <TableCell singleLine>{data.joinedClubs.android}</TableCell>
                <TableCell singleLine>{data.joinedClubs.ios}</TableCell>
                <TableCell singleLine>{data.joinedClubs.mWeb}</TableCell>
                <TableCell singleLine>{data.joinedClubs.web}</TableCell>
                <TableCell singleLine>{data.joinedClubs.total}</TableCell>
                <TableCell singleLine>
                  {data.registeredEvents.android}
                </TableCell>
                <TableCell singleLine>{data.registeredEvents.ios}</TableCell>
                <TableCell singleLine>{data.registeredEvents.mWeb}</TableCell>
                <TableCell singleLine>{data.registeredEvents.web}</TableCell>
                <TableCell singleLine>{data.registeredEvents.total}</TableCell>
                <TableCell singleLine>{data.creationStats.user}</TableCell>
                <TableCell singleLine>{data.creationStats.college}</TableCell>
                <TableCell singleLine>{data.creationStats.club}</TableCell>
                <TableCell singleLine>{data.creationStats.event}</TableCell>
                <TableCell singleLine>{data.creationStats.report}</TableCell>
                <TableCell singleLine>{data.creationStats.contacts}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Helmet>
        <title>{"Dashboard"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
