const UPDATE_CLUB = "UPDATE_CLUB";
const REMOVE_CLUB = "REMOVE_CLUB";
const FETCH_CLUB = "FETCH_CLUB";
const LISTEN_TO_SELECTED_CLUB = "LISTEN_TO_SELECTED_CLUB";
const CLEAR_SELECTED_CLUB = "CLEAR_SELECTED_CLUB";
const CLEAR_CLUBS = "CLEAR_CLUBS";

export const CLUB_RETAIN_STATE = "CLUB_RETAIN_STATE";

export function addClubs(clubs) {
  return {
    type: FETCH_CLUB,
    payload: clubs,
  };
}

export function listenToSelectedClub(club) {
  return {
    type: LISTEN_TO_SELECTED_CLUB,
    payload: club,
  };
}

export function clearSelectedClub() {
  return {
    type: CLEAR_SELECTED_CLUB,
  };
}

export function updateClub(club) {
  return {
    type: UPDATE_CLUB,
    payload: club,
  };
}

export function removeClub(clubId) {
  return {
    type: REMOVE_CLUB,
    payload: clubId,
  };
}

export function clearClubs() {
  return {
    type: CLEAR_CLUBS,
  };
}

const initialState = {
  clubs: [],
  selectedClub: null,
  retainState: false,
};

export default function clubReducer(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_CLUB:
      return {
        ...state,
        clubs: state.clubs.map((evt) =>
          evt._id === payload._id ? payload : evt
        ),
      };

    case REMOVE_CLUB:
      return {
        ...state,
        clubs: [...state.clubs.filter((evt) => evt._id !== payload)],
      };
    case FETCH_CLUB:
      return {
        ...state,
        clubs: payload,
      };
    case LISTEN_TO_SELECTED_CLUB:
      return {
        ...state,
        selectedClub: payload,
      };
    case CLEAR_SELECTED_CLUB:
      return {
        ...state,
        selectedClub: null,
      };
    case CLEAR_CLUBS:
      return {
        ...state,
        clubs: [],
      };
    case CLUB_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
