const CREATE_FEATURED_AD = "CREATE_FEATURED_AD";
const UPDATE_FEATURED_AD = "UPDATE_FEATURED_AD";
const DELETE_FEATURED_AD = "DELETE_FEATURED_AD";
const FETCH_FEATURED_ADS = "FETCH_FEATURED_ADS";

export const CLEAR_FEATURED_ADS = "CLEAR_FEATURED_ADS";

export function createFeaturedAd(ad) {
  return {
    type: CREATE_FEATURED_AD,
    payload: ad,
  };
}

export function updateFeaturedAd(ad) {
  return {
    type: UPDATE_FEATURED_AD,
    payload: ad,
  };
}

export function deleteFeaturedAd(adId) {
  return {
    type: DELETE_FEATURED_AD,
    payload: adId,
  };
}

export function addFeaturedAds(ads) {
  return {
    type: FETCH_FEATURED_ADS,
    payload: ads,
  };
}

const initialState = {
  feturedAd: [],
};

export default function featuedAdReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_FEATURED_AD:
      return {
        ...state,
        feturedAd: [payload, ...state.feturedAd],
      };
    case UPDATE_FEATURED_AD:
      return {
        ...state,
        feturedAd: state.feturedAd.map((evt) =>
          evt._id === payload._id ? payload : evt
        ),
      };
    case DELETE_FEATURED_AD:
      return {
        ...state,
        feturedAd: [...state.feturedAd.filter((evt) => evt._id !== payload)],
      };
    case FETCH_FEATURED_ADS:
      return {
        ...state,
        feturedAd: payload,
      };
    case CLEAR_FEATURED_ADS:
      return {
        ...state,
        feturedAd: [],
      };
    default: {
      return state;
    }
  }
}
