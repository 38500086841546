import {
  addReports,
  reportsFailed,
  reportsLoading,
  updateReport,
} from "../../features/report/reportReducer";

export const updateReportApi = (report) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "report/" + report._id, {
    method: "PUT",
    body: JSON.stringify(report),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateReport(response)))
    .catch((error) => {
      console.log("Report updated ", error.message);
    });
};

export const fetchReportsApi = (query) => (dispatch) => {
  dispatch(reportsLoading(true));

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "report" + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) =>
      dispatch(
        addReports({
          reports: response.reports,
          moreReport: response.moreReports,
          lastDocId: response.lastDocId,
        })
      )
    )
    .catch((error) => dispatch(reportsFailed(error.message)));
};

export const deleteReportApi = (reportId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "report/" + reportId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((reports) => {
      console.log("Report Deleted", reports);
      dispatch(addReports(reports));
    })
    .catch((error) => dispatch(reportsFailed(error.message)));
};