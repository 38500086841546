import {
  addClubs,
  listenToSelectedClub,
  removeClub,
} from "../../features/club/clubReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const fetchClubsApi = (query) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs" + query)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(asyncActionFinish());
      dispatch(addClubs(response));
    })
    .catch((error) => dispatch(asyncActionError(error.message)));
};

export const deleteClubApi = (clubId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs/" + clubId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log("Club Deleted", response);
      dispatch(removeClub(clubId));
    })
    .catch((error) => console.log(error.message));
};

export const updateClubApi = (club) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs/" + club._id, {
    method: "PUT",
    body: JSON.stringify(club),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(listenToSelectedClub(response)))
    .catch((error) => {
      console.log("Club updated ", error.message);
      alert("Your Club could not be updated\nError: " + error.message);
    });
};

export const fetchSingleClubApi = (clubId) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "clubs/" + clubId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response) dispatch(asyncActionError("Club not found!"));
      else {
        dispatch(listenToSelectedClub(response));
        dispatch(asyncActionFinish());
      }
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError("URL doesn't exist!"));
    });
};