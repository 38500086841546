import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import modalReducer from "../common/modals/modalReducer";
import asyncReducer from "../async/asyncReducer";
import authReducer from "../../features/auth/authReducer";
import collegeReducer from "../../features/college/collegeReducer";
import clubReducer from "../../features/club/clubReducer";
import reportReducer from "../../features/report/reportReducer";
import contactsReducer from "../../features/contacts/contactsReducer";
import homeReducer from "../../features/home/homeReducer";
import featuedAdReducer from "../../features/featuredAd/featuredAdReducer";

const rootReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    modal: modalReducer,
    async: asyncReducer,
    auth: authReducer,
    home: homeReducer,
    college: collegeReducer,
    club: clubReducer,
    report: reportReducer,
    contacts: contactsReducer,
    ads: featuedAdReducer
  });

export default rootReducers;
