import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ReportListItem from "./ReportListItem";

export default function ReportList({
  reports,
  getNextReports,
  loading,
  moreReports,
}) {
  return (
    <>
      {reports.length !== 0 ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextReports}
          hasMore={!loading && moreReports}
          initialLoad={false}
        >
          {reports.map((report) => (
            <ReportListItem report={report} key={report._id} />
          ))}
        </InfiniteScroll>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell outline" />
            You have no pending reports.
          </Header>
          <Button as={Link} to="/home" primary>
            Go to Home Page
          </Button>
        </Segment>
      )}
    </>
  );
}
