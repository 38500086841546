const FETCH_USER_ACTIVITY = "FETCH_USER_ACTIVITY";
const FETCH_CREATION_STATS = "FETCH_CREATION_STATS";
const SORT_BY = "SORT_BY";

export const CLEAR_DETAILS = "CLEAR_DETAILS";

export function fetchUserActivities(data) {
  return {
    type: FETCH_USER_ACTIVITY,
    payload: data,
  };
}

export function fetchCreationStats(data) {
  return {
    type: FETCH_CREATION_STATS,
    payload: data,
  };
}

export function sortyBy(data) {
  return {
    type: SORT_BY,
    payload: data,
  };
}

const initialState = {
  user_activities: [],
  creation_stats: [],
  filter: "days",
  feturedAd: [],
};

export default function homeReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_USER_ACTIVITY:
      return {
        ...state,
        user_activities: payload,
      };
    case FETCH_CREATION_STATS:
      return {
        ...state,
        creation_stats: payload,
      };
    case SORT_BY:
      return {
        ...state,
        filter: payload,
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        user_activities: [],
        creation_stats: [],
      };
    default: {
      return state;
    }
  }
}
