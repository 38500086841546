import {
  addContacts,
  contactsFailed,
  contactsLoading,
  updateContacts,
} from "../../features/contacts/contactsReducer";

export const updateContactApi = (contact) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "contact/" + contact._id, {
    method: "PUT",
    body: JSON.stringify(contact),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(updateContacts(response)))
    .catch((error) => {
      console.log("Contact updated ", error.message);
    });
};

export const fetchContactsApi = (query) => (dispatch) => {
  dispatch(contactsLoading(true));

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "contact" + query, {
    headers: {
      Authorization: bearer,
    },
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) =>
      dispatch(
        addContacts({
          contacts: response.contacts,
          moreContact: response.moreContacts,
          lastDocId: response.lastDocId,
        })
      )
    )
    .catch((error) => dispatch(contactsFailed(error.message)));
};

export const deleteContactApi = (contactId) => (dispatch) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "contact/" + contactId, {
    method: "DELETE",
    headers: {
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((contacts) => {
      console.log("Contact Deleted", contacts);
      dispatch(addContacts(contacts));
    })
    .catch((error) => dispatch(contactsFailed(error.message)));
};