import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  Confirm,
  Dropdown,
  Image,
  Menu,
  MenuItem,
  MenuMenu,
} from "semantic-ui-react";
import { logoutUser, listenToCurrentUserProfile } from "../auth/authReducer";
import { fetchUserProfileApi } from "../../app/apiRequest/loginService";
import LoadingComponent from "../../app/layout/LoadingComponent";

export default function Navbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const { pathname } = useSelector((state) => state.router.location);
  const { user, currentUserProfile } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);
  const [activeItem, setActiveItem] = useState("home");

  useEffect(() => {
    dispatch(fetchUserProfileApi(user));
  }, [dispatch, user]);

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  const handleSetActiveItem = (e, { name }) => {
    history.push(`/${name}`);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(listenToCurrentUserProfile(null));
    history.push("/");
  };

  if ((loading && !currentUserProfile) || (!error && !currentUserProfile))
    return <LoadingComponent content="Loading..." />;

  return (
    <div>
      <Menu borderless fixed="top">
        <Menu.Item style={{ marginLeft: 15 }} header as={Link} to={"/"}>
          <img src="/logo.png" alt="logo" style={{ marginRight: 8 }} />
          <span style={{ color: "black" }}>Club-Chat</span>
        </Menu.Item>
        <MenuItem
          name="home"
          icon="home"
          active={activeItem.includes("home")}
          onClick={handleSetActiveItem}
        />
        <MenuItem
          name="college"
          icon="university"
          active={activeItem.includes("college")}
          onClick={handleSetActiveItem}
        />
        <MenuItem
          name="club"
          icon="users"
          active={activeItem.includes("club")}
          onClick={handleSetActiveItem}
        />
        <MenuItem
          name="report"
          icon="exclamation circle"
          active={activeItem.includes("report")}
          onClick={handleSetActiveItem}
        />
        <MenuItem
          name="contacts"
          icon="text telephone"
          active={activeItem.includes("contacts")}
          onClick={handleSetActiveItem}
        />
        <MenuItem
          name="featuredAd"
          icon="adversal"
          active={activeItem.includes("featuredAd")}
          onClick={handleSetActiveItem}
        />
        <MenuMenu position="right">
          <Menu.Item>
            <Image
              avatar
              spaced="right"
              src={currentUserProfile?.user.userImg}
            />
            <Dropdown
              pointing="top left"
              text={
                <span style={{ color: "rgb(66,131,202)" }}>
                  {currentUserProfile?.user.firstname}{" "}
                  {currentUserProfile?.user.lastname}
                </span>
              }
            >
              <Dropdown.Menu size="huge">
                <Dropdown.Item
                  text="Sign out"
                  icon="power"
                  onClick={() => setOpenConfirm(true)}
                />
              </Dropdown.Menu>
            </Dropdown>
            <Confirm
              open={openConfirm}
              size="mini"
              content="Log out of Club-Chat?"
              onCancel={() => setOpenConfirm(false)}
              onConfirm={handleLogout}
            />
          </Menu.Item>
        </MenuMenu>
        <MenuItem />
      </Menu>
    </div>
  );
}
