import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCollegeApi,
  fetchSingleCollegeApi,
  updateCollegeApi,
} from "../../app/apiRequest/collegeService";
import LoadingComponent from "../../app/layout/LoadingComponent";
import {
  Button,
  Confirm,
  Icon,
  Image,
  Item,
  Label,
  List,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

export default function CollegePage({ match }) {
  const { selectedCollege } = useSelector((state) => state.college);
  const { loading, error } = useSelector((state) => state.async);
  const dispatch = useDispatch();
  const history = useHistory();
  const [meta, setMeta] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    if (!selectedCollege) return;
    for (const domain of selectedCollege.domain) {
      setMeta((a) => a + domain + " | ");
    }
  }, [selectedCollege]);

  useEffect(() => {
    dispatch(fetchSingleCollegeApi(match.params.id));
  }, [dispatch, match.params.id]);

  const handleUpdate = () => {
    dispatch(
      updateCollegeApi({ ...selectedCollege, verified: true, isVerified: true })
    );
    toast.success("College Verified");
  };

  const handleDelete = () => {
    dispatch(deleteCollegeApi(match.params.id));
    history.push("/college");
    toast.success("College Page Deleted");
  };

  if ((loading && !selectedCollege) || (!selectedCollege && !error))
    return <LoadingComponent content="Loading college..." />;

  // var isAdmin = selectedCollege.admin.some((item) => item._id === user);

  return (
    <div>
      <Button
        icon="arrow left"
        floated="left"
        style={{ top: 78, left: 60, position: "fixed", color: "blue" }}
        onClick={() => history.goBack()}
      />
      <div style={{ marginRight: 20 }}>
        <Item.Group>
          <Item>
            <Item.Content>
              <Image
                src={selectedCollege.collegeImg}
                size="medium"
                floated="right"
                style={{ marginTop: 16 }}
              />
              <Item.Meta style={{ color: "blue", display: "block" }}>
                {meta}
              </Item.Meta>
              <Item.Header style={{ maxWidth: 600 }}>
                {selectedCollege.collegeName}
              </Item.Header>
              <Item.Description>
                <p style={{ width: 600 }}>{selectedCollege.description}</p>
                <List horizontal relaxed style={{ display: "block" }}>
                  {selectedCollege.website && (
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkify" />{" "}
                        <a
                          href={selectedCollege.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {selectedCollege.website}
                        </a>
                      </List.Content>
                    </List.Item>
                  )}
                  <List.Item>
                    <List.Content>
                      <List.Icon name="mail" />{" "}
                      <a
                        href={`mailto:${selectedCollege.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {selectedCollege.email}
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
                {selectedCollege.instagram && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="instagram" />{" "}
                        <a href={selectedCollege.instagram} target="#blank">
                          {selectedCollege.instagram}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                {selectedCollege.linkedIn && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkedin" />{" "}
                        <a href={selectedCollege.linkedIn} target="#blank">
                          {selectedCollege.linkedIn}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                <List horizontal relaxed style={{ display: "block" }}>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="marker" />
                      {selectedCollege.city.address}
                    </List.Content>
                  </List.Item>
                </List>
              </Item.Description>
              <Item.Extra>
                {!selectedCollege.verified && (
                  <Button.Group>
                    <Button onClick={handleUpdate} basic color="green">
                      Approve
                    </Button>
                    <Button
                      onClick={() => setOpenConfirm(true)}
                      basic
                      color="red"
                    >
                      Decline
                    </Button>
                  </Button.Group>
                )}
                {selectedCollege.verified && (
                  <Label basic color="blue">
                    <Icon name="check circle outline" />
                    Verified
                  </Label>
                )}
                <Button
                  color="blue"
                  icon="eye"
                  content="View Creator"
                  style={{ marginTop: 30 }}
                  onClick={() => {
                    if (selectedCollege.creator) {
                      window.open(
                        `https://clubchat.live/profile/${selectedCollege.creator}`,
                        "_blank"
                      );
                    } else {
                      toast.error(
                        "College has been created before user was created. So there is no creator."
                      );
                    }
                  }}
                />
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
        <Confirm
          open={openConfirm}
          size="mini"
          content="Delete This College Page."
          onCancel={() => setOpenConfirm(false)}
          onConfirm={handleDelete}
        />
      </div>
    </div>
  );
}
