const REPORTS_LOADING = "REPORTS_LOADING";
const REPORTS_FAILED = "REPORTS_FAILED";
const ADD_REPORTS = "ADD_REPORTS";
const UPDATE_REPORT = "UPDATE_REPORT";
const DELETE_REPORT = "DELETE_REPORT";
export const REPORT_RETAIN_STATE = "REPORT_RETAIN_STATE";

export const reportsLoading = () => ({
  type: REPORTS_LOADING,
});

export const reportsFailed = (errmess) => ({
  type: REPORTS_FAILED,
  payload: errmess,
});

export const addReports = ({ reports, moreReport, lastDocId }) => ({
  type: ADD_REPORTS,
  payload: { reports, moreReport, lastDocId },
});

export function updateReport(report) {
  return {
    type: UPDATE_REPORT,
    payload: report,
  };
}

export function deleteReport(reportId) {
  return {
    type: DELETE_REPORT,
    payload: reportId,
  };
}

const initialState = {
  isLoading: true,
  errMess: null,
  reports: [],
  moreReport: true,
  lastDocId: null,
  retainState: false,
};

export default function reportReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_REPORTS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        reports: [...state.reports, ...payload.reports],
        moreReport: payload.moreReport,
        lastDocId: payload.lastDocId,
      };

    case REPORTS_LOADING:
      return { ...state, isLoading: true, errMess: null };

    case REPORTS_FAILED:
      return { ...state, isLoading: false, errMess: payload };

    case UPDATE_REPORT:
      return {
        ...state,
        reports: state.reports.map((evt) =>
          evt._id === payload._id ? payload : evt
        ),
      };

    case DELETE_REPORT:
      return {
        ...state,
        reports: [...state.reports.filter((evt) => evt._id !== payload)],
      };
    case REPORT_RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
