import React from "react";
import { Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import LoginHomePage from "../../features/auth/SignInPage";
import Navbar from "../../features/nav/Navbar";
import ModalManager from "../common/modals/ModalManager";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import HomePage from "../../features/home/HomePage";
import CollegeDashboard from "../../features/college/CollegeDashboard";
import ClubDashboard from "../../features/club/ClubDashboard";
import ReportDashboard from "../../features/report/ReportDashboard";
import ContactsDashboard from "../../features/contacts/ContactsDashboard";
import CollegePage from "../../features/college/CollegePage";
import ClubAboutPage from "../../features/club/ClubPage";
import FeaturedFeed from "../../features/featuredAd/FeaturedFeed";

export default function App() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <div>
      <Route exact path="/" component={LoginHomePage} />
      <div className="main-content">
        <ModalManager />
        <ToastContainer
          position="bottom-right"
          theme="colored"
          hideProgressBar
        />
        <Route
          path={"/(.+)"}
          render={() => (
            <div>
              <div>
                <ScrollToTop />
                <PrivateRoute exact path="/home" component={HomePage} />
                <PrivateRoute exact path="/college" component={CollegeDashboard} />
                <PrivateRoute exact path="/club" component={ClubDashboard} />
                <PrivateRoute exact path="/college/:id" component={CollegePage} />
                <PrivateRoute exact path="/club/:id" component={ClubAboutPage} />
                <PrivateRoute exact path="/report" component={ReportDashboard} />
                <PrivateRoute exact path="/contacts" component={ContactsDashboard} />
                <PrivateRoute exact path="/featuredAd" component={FeaturedFeed} />
                <Route
                  exact
                  path="/test"
                  render={() =>
                    !isAuthenticated ? <Redirect to="/" /> : <LoginHomePage />
                  }
                />
              </div>
              <Navbar />
            </div>
          )}
        />
      </div>
    </div>
  );
}
