import React from "react";
import { useSelector } from "react-redux";
import ViewAdminModal from "../../../features/club/ViewAdminModal";
import FeaturedAdForm from "../../../features/featuredAd/FeaturedAdForm";

export default function ModalManager() {
  const modalLookup = {
    ViewAdminModal,
    FeaturedAdForm
  };
  const currentModal = useSelector((state) => state.modal);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span> {renderedModal} </span>;
}
