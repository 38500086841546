import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Header, Icon, Image, Segment } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { fetchClubsApi } from "../../app/apiRequest/clubService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";

export default function ClubDashboard() {
  const dispatch = useDispatch();
  const { clubs } = useSelector((state) => state.club);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchClubsApi("?college[$exists]=true&college_status=rejected"));
    // eslint-disable-next-line
  }, [dispatch]);

  if ((loading && !clubs) || (!clubs && !error))
    return <LoadingComponent content="Loading ..." />;

  return (
    <div>
      {clubs.length > 0 ? (
        <div className="card-container">
          {clubs.map((club) => (
            <div className="card-item">
              <Image
                src={club.clubImg}
                as={"a"}
                alt="img"
                href={`/club/${club._id}`}
              />
              <div className="card-content">
                <h5>{club.clubName}</h5>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell outline" />
            You have no pending clubs to approve.
          </Header>
          <Button as={Link} to="/home" primary>
            Go to Home Page
          </Button>
        </Segment>
      )}
      <Helmet>
        <title>{"Dashboard"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
