import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ContactsListItem from "./ContactsListItem";

export default function ContactsList({
  contacts,
  getNextContacts,
  loading,
  moreContact,
}) {
  return (
    <>
      {contacts.length !== 0 ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextContacts}
          hasMore={!loading && moreContact}
          initialLoad={false}
        >
          {contacts.map((contact) => (
            <ContactsListItem contact={contact} key={contact._id} />
          ))}
        </InfiniteScroll>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell outline" />
            You have no pending reports.
          </Header>
          <Button as={Link} to="/home" primary>
            Go to Home Page
          </Button>
        </Segment>
      )}
    </>
  );
}
