import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchReportsApi } from "../../app/apiRequest/reportService";
import { REPORT_RETAIN_STATE } from "./reportReducer";
import ReportItemPlaceholder from "./ReportItemPlaceholder";
import ReportList from "./ReportList";

export default function ReportDashboard() {
  const dispatch = useDispatch();
  const { isLoading, retainState, reports, moreReport, lastDocId } =
    useSelector((state) => state.report);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if (retainState) return;
    setLoadingInitial(true);
    dispatch(fetchReportsApi("?status=pending")).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: REPORT_RETAIN_STATE });
    };
  }, [dispatch, retainState]);

  function handleFetchNextReports() {
    dispatch(fetchReportsApi(`?status=pending&_id[$lt]=${lastDocId}`));
  }

  return (
    <>
      {loadingInitial && (
        <>
          <ReportItemPlaceholder />
          <ReportItemPlaceholder />
        </>
      )}
      <ReportList
        reports={reports}
        getNextReports={handleFetchNextReports}
        loading={isLoading}
        moreReport={moreReport}
      />
      <Loader active={isLoading} />
      <Helmet>
        <title>{"Report"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </>
  );
}
