import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Item, Header, Button } from "semantic-ui-react";
import { fetchFeaturedAdApi } from "../../app/apiRequest/featuredAdService";
import FeaturedAdItem from "./FeaturedAdItem";
import { openModal } from "../../app/common/modals/modalReducer";
import { Helmet } from "react-helmet";

export default function FeaturedFeed({ userId }) {
  const dispatch = useDispatch();
  const { feturedAd } = useSelector((state) => state.ads);

  useEffect(() => {
    if (feturedAd.length > 0) return;
    dispatch(fetchFeaturedAdApi(userId));
    // eslint-disable-next-line
  }, [dispatch, userId]);

  return (
    <div>
      <Header content="Featured opportunities" color="blue" />
      <Item.Group>
        {feturedAd.map((ad, i) => (
          <FeaturedAdItem key={i} ad={ad} />
        ))}
      </Item.Group>
      <Button
        icon="add"
        color="blue"
        style={{ position: "fixed", right: 50, top: 65 }}
        content="Featured Oppurtunities"
        onClick={() =>
          dispatch(
            openModal({
              modalType: "FeaturedAdForm",
              modalProps: {},
            })
          )
        }
      />
      <Helmet>
        <title>{"Featured Ad"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
