import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Header, Icon, Segment } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { fetchCollegesApi } from "../../app/apiRequest/collegeService";
import { Helmet } from "react-helmet";

export default function CollegeDashboard() {
  const dispatch = useDispatch();
  const { colleges } = useSelector((state) => state.college);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    dispatch(fetchCollegesApi("?verified=false"));
    // eslint-disable-next-line
  }, [dispatch]);

  if ((loading && !colleges) || (!colleges && !error))
    return <LoadingComponent content="Loading ..." />;

  return (
    <div>
      {colleges.length > 0 ? (
        <Card.Group>
          {colleges.map((college) => (
            <Card
              key={college._id}
              image={college.collegeImg}
              header={college.collegeName}
              meta={college.city.address}
              description={<p className="three-lines">{college.description}</p>}
              as={Link}
              to={`/college/${college._id}`}
              extra={
                <>
                  <Icon name="calendar alternate outline" />
                  {new Date(college.createdAt).toLocaleDateString()}
                </>
              }
            />
          ))}
        </Card.Group>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell outline" />
            You have no pending college to verify.
          </Header>
          <Button as={Link} to="/home" primary>
            Go to Home Page
          </Button>
        </Segment>
      )}
      <Helmet>
        <title>{"College"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
