import React from "react";
import "../report/ReportListItem.css";
import { useDispatch } from "react-redux";
import { updateContactApi } from "../../app/apiRequest/contactService";

const ContactsListItem = ({ contact }) => {
  const dispatch = useDispatch();
  const onStatusChange = (status) => {
    dispatch(updateContactApi({ ...contact, status: status }));
  };
  return (
    <div className="report">
      <div className="top-bar">
        <div className="info">
          <img
            src={
              contact.user
                ? contact.user.userImg
                : "https://react.semantic-ui.com/images/avatar/small/elliot.jpg"
            }
            avatar
            alt="Profile"
          />
          <div className="name">
            {contact.user && (
              <a
                href={`https://clubchat.live/profile/${contact.user._id}`}
                target="#blank"
              >
                {contact.user.displayName}
              </a>
            )}
            {!contact.user && <h3>Not a SignedIn User</h3>}
            <h4>{contact.user ? contact.user.username : ""}</h4>
          </div>
        </div>
        <div>
          <select
            value={contact.status}
            onChange={(e) => onStatusChange(e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="processiong">Processing</option>
            <option value="resolved">Resolved</option>
          </select>
        </div>
      </div>
      <div className="main">
        <p>
          <b>{"Name : "}</b> {contact.name} <b>{"Email : "}</b> {contact.email}{" "}
          <b> {"Phone : "}</b> {contact.phone}
        </p>
        <p>
          <b>{"Reason : "}</b> {contact.details}
        </p>
        {contact.file !== "" && (
          <div className="attachment">
            <a href={contact.file} target="#blank">
              {contact.file}
            </a>
          </div>
        )}
      </div>
      <div className="btn">
        <>
          <i className="fa-solid fa-bug"></i> Contact Type: {contact.type}
        </>
      </div>
    </div>
  );
};

export default ContactsListItem;
