const CREATE_COLLEGE = "CREATE_COLLEGE";
const LISTEN_TO_COLLEGE = "LISTEN_TO_COLLEGE";
const CLEAR_SELECTED_COLLEGE = "CLEAR_SELECTED_COLLEGE";
const UPDATE_COLLEGE = "UPDATE_COLLEGE";
const DELETE_COLLEGE = "DELETE_COLLEGE";
const FETCH_COLLEGES = "FETCH_COLLEGES";

export const CLEAR_COLLEGES = "CLEAR_COLLEGES";

export function createCollege(college) {
  return {
    type: CREATE_COLLEGE,
    payload: college,
  };
}

export function listenToCollege(college) {
  return {
    type: LISTEN_TO_COLLEGE,
    payload: college,
  };
}

export function clearSelectedCollege() {
  return {
    type: CLEAR_SELECTED_COLLEGE,
  };
}

export function updateCollege(college) {
  return {
    type: UPDATE_COLLEGE,
    payload: college,
  };
}

export function deleteCollege(collegeId) {
  return {
    type: DELETE_COLLEGE,
    payload: collegeId,
  };
}

export function addColleges(colleges) {
  return {
    type: FETCH_COLLEGES,
    payload: colleges,
  };
}

const initialState = {
  selectedCollege: null,
  colleges: [],
};

export default function collegeReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_COLLEGE:
      return {
        ...state,
        selectedCollege: payload,
      };
    case LISTEN_TO_COLLEGE:
      return {
        ...state,
        selectedCollege: payload,
      };
    case CLEAR_SELECTED_COLLEGE:
      return {
        ...state,
        selectedCollege: null,
      };
    case UPDATE_COLLEGE:
      return {
        ...state,
        selectedCollege: payload,
      };
    case DELETE_COLLEGE:
      return {
        ...state,
        colleges: [...state.colleges.filter((evt) => evt._id !== payload)],
      };
    case FETCH_COLLEGES:
      return {
        ...state,
        colleges: payload,
      };
    case CLEAR_COLLEGES:
      return {
        ...state,
        colleges: [],
      };
    default: {
      return state;
    }
  }
}
