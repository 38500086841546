import { toast } from "react-toastify";
import {
  listenToCurrentUserProfile,
  loginError,
  receiveLogin,
  requestLogin,
} from "../../features/auth/authReducer";
import { closeModal } from "../common/modals/modalReducer";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";

export const loginUser = (creds, setErrors) => (dispatch) => {
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(requestLogin());

  return fetch(process.env.REACT_APP_API_BASE_URL + "users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creds),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.success && response.token) {
        if (response.user.admin) {
          // If login was successful, set the token in local storage
          localStorage.setItem("token", response.token);
          localStorage.setItem("creds", response.user._id);
          dispatch(receiveLogin(response));
          dispatch(closeModal());
        } else {
          toast.error("You are not authorized!");
        }
      } else {
        var error = new Error("Error: " + response.err.message);
        error.response = response;
        throw error;
      }
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      setErrors({ auth: error.message });
    });
};

export const fetchUserProfileApi = (userId) => (dispatch) => {
  dispatch(asyncActionStart());

  return fetch(process.env.REACT_APP_API_BASE_URL + "profile/" + userId)
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        throw error;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(listenToCurrentUserProfile(response));
      dispatch(asyncActionFinish());
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(asyncActionError(error.message));
    });
};
