import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import ContactsItemPlaceholder from "./ContactsItemPlaceholder";
import { CONTACT_RETAIN_STATE } from "./contactsReducer";
import { fetchContactsApi } from "../../app/apiRequest/contactService";
import ContactsList from "./ContactsList";

export default function ContactsDashboard() {
  const dispatch = useDispatch();
  const { isLoading, retainState, contacts, moreContact, lastDocId } =
    useSelector((state) => state.contacts);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if (retainState) return;
    setLoadingInitial(true);
    dispatch(fetchContactsApi("?status=pending")).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: CONTACT_RETAIN_STATE });
    };
  }, [dispatch, retainState]);

  function handleFetchNextContacts() {
    dispatch(fetchContactsApi(`?status=pending&_id[$lt]=${lastDocId}`));
  }

  return (
    <>
      {loadingInitial && (
        <>
          <ContactsItemPlaceholder />
          <ContactsItemPlaceholder />
        </>
      )}
      <ContactsList
        contacts={contacts}
        getNextContacts={handleFetchNextContacts}
        loading={isLoading}
        moreContact={moreContact}
      />
      <Loader active={isLoading} />
      <Helmet>
        <title>{"Contacts"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </>
  );
}
