import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openModal } from "../../app/common/modals/modalReducer";
import { deleteAdApi } from "../../app/apiRequest/featuredAdService";
import { toast } from "react-toastify";

export default function FeaturedAdItem({ ad }) {
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false);

  var image = "https://react.semantic-ui.com/images/wireframe/image.png";
  var header = "Header";
  var meta = "Description";
  var link = "/featuredAd";
  if (ad.fest) {
    image = ad.fest.festImg;
    header = ad.fest.festName;
    meta = ad.fest.college.collegeName;
    link = `https://clubchat.live/club/fest/${ad.fest._id}`;
  } else if (ad.college) {
    image = ad.college.collegeImg;
    header = ad.college.collegeName;
    meta = ad.college.city?.address;
    link = `https://clubchat.live/college/${ad.college._id}`;
  } else if (ad.club) {
    image = ad.club.clubImg;
    header = ad.club.clubName;
    if (ad.club.learningXClub) {
      meta = "LearningX club";
    } else if (ad.club.college) {
      meta = ad.club.college.collegeName;
    } else {
      meta = "Private Club";
    }
    link = `https://clubchat.live/club/about/${ad.club._id}`;
  } else if (ad.event) {
    image = ad.event.eventImg;
    header = ad.event.eventTitle;
    meta = ad.event.college?.collegeName;
    link = `https://clubchat.live/events/${ad.event._id}`;
  }

  const toggleOptions = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setShowOptions((prev) => !prev);
  };

  const handleOptionClick = (action) => {
    setShowOptions(false); // Hide options after selection
    action();
  };

  const handleEdit = () => {
    setShowOptions(false); // Hide options after selection
    dispatch(
      openModal({
        modalType: "FeaturedAdForm",
        modalProps: { ad: ad },
      })
    );
  };

  const handleDelete = () => {
    setShowOptions(false); // Hide options after selection
    dispatch(deleteAdApi(ad._id));
    toast.success("Ad deleted");
  };

  return (
    <div
      style={{
        position: "relative",
        padding: 10,
        border: "1px solid #ccc",
        borderRadius: "5px",
        marginBottom: "10px",
        cursor: "pointer",
      }}
      onClick={toggleOptions}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={image} alt={header} style={{ width: 200, marginRight: 10 }} />
        <div>
          <Link to={link} style={{ textDecoration: "none", color: "black" }}>
            <h4>{header}</h4>
          </Link>
          <p>{meta}</p>
          <p>{ad.description}</p>
        </div>
      </div>
      {showOptions && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            zIndex: 1000,
            width: "200px",
          }}
        >
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            <li
              style={{
                padding: "10px",
                borderBottom: "1px solid #eee",
                cursor: "pointer",
              }}
              onClick={() =>
                handleOptionClick(() => window.open(link, "_blank"))
              }
            >
              Open Item
            </li>
            <li
              style={{
                padding: "10px",
                borderBottom: "1px solid #eee",
                cursor: "pointer",
              }}
              onClick={handleEdit}
            >
              Edit Item
            </li>
            <li
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={handleDelete}
            >
              Delete Item
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
